import React, { Component } from 'react';
import './feedback.scss';
import { Heading } from '../../components/Heading/Heading';
// core version + navigation, pagination modules:
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';

class Feedback extends Component {
  render() {
    return (
      <>
        <section className="feedback">
          <div className="container">
            <div className="gr-12 no-gutter@md">
              <div className="feedback__holder">
                <div className="feedback__wrap">
                  <div className="feedback__title">
                    <Heading
                      level="h3"
                      decorationDown
                      centeredOnMobile
                    >
                      Feedback
                    </Heading>
                  </div>

                  <Swiper
                    modules={[Navigation]}
                    navigation={{
                      el: ".feedback-navigation",
                      nextEl: ".feedback-next",
                      prevEl: ".feedback-prev",
                    }}
                  >
                    <SwiperSlide>
                      <p className="feedback__description">
                        “Over the years, I have worked with Kickstage on numerous projects. Whether in app or web development, every project is approached with enthusiasm, care and attention to detail. I especially enjoy the regular progress updates and overall great communication.”
                      </p>
                      <div className="feedback__author">
                        <p className="name">Uwe Placzek</p>
                        <p className="title">UP Management</p>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                  <div className="feedback__controls ">
                    <div className="swiper-button-prev feedback-prev"></div>
                    <div className="swiper-button-next feedback-next"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
};

export default Feedback;