import React, { useEffect, useRef, Fragment } from "react";
import "./hero.scss";
import classNames from "classnames";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Button } from "../Button/Button";
import { ArrowRight } from "react-feather";
import FadeDown from "../FadeDown/FadeDown";

const foldVideoMp4 = "/kstg-hero-animation.mp4";
const foldVideoWebm = "/kstg-hero-animation.webm";
const foldVideoPoster = "/kstg-hero-animation-poster.jpg";

export const Hero = ({
  image,
  textLine1,
  textLine2,
  textLine3,
  textLine4,
  link,
}) => {
  const headingRef = useRef();
  const gsapQuery = gsap.utils.selector(headingRef);
  const revealTextRefs = useRef([]);

  const addToRevealTextRefs = (el) => {
    if (el && !revealTextRefs.current.includes(el)) {
      revealTextRefs.current.push(el);
    }
  };

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const revealTextItems = gsapQuery(".reveal-text");
    const revealTextBg = gsapQuery(".reveal-text div.overlay");

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: headingRef.current,
        start: "50% bottom",
        toggleActions: "restart none none reset",
        once: true,
      },
    });
    tl.to(revealTextItems, { width: "auto", stagger: 0.2, ease: "power2.out" }); // for text
    tl.to(
      revealTextBg,
      { left: "100%", stagger: 0.2, ease: "power2.out" },
      0.5
    ); // for the background

    return () => {
      tl.kill();
    };
  }, [gsapQuery]);

  return (
    <section
      className={classNames("hero", "hero--homepage")}
      id="homepage-hero"
    >
      <div className="container">
        <div className="gr-12 no-gutter@md">
          <div className="hero__inner">
            <div className="hero__text">
              <h1 className="h1 intro" ref={headingRef}>
                <span className="reveal-text nowrap" ref={addToRevealTextRefs}>
                  {textLine1}{" "}
                  <div className="overlay"></div>
                </span>
                <div className="clear"></div>
                <span className="reveal-text nowrap" ref={addToRevealTextRefs}>
                  {textLine2}
                  <div className="overlay"></div>
                </span>
                <div className="clear"></div>
                <span className="reveal-text nowrap" ref={addToRevealTextRefs}>
                  {textLine3}
                  <div className="overlay"></div>
                </span>
                <div className="clear"></div>
                <span className="reveal-text nowrap" ref={addToRevealTextRefs}>
                  {textLine4}
                  <div className="overlay"></div>
                </span>
              </h1>
              <FadeDown>
                <Button
                  secondary
                  withIcon
                  link="/contact/"
                  icon={
                    <Fragment>
                      <ArrowRight size="24" />
                    </Fragment>
                  }
                >
                  Get in touch
                </Button>
              </FadeDown>
            </div>

            <div className="hero__illustration" id="homepage-video">
              <video loop autoPlay muted poster={foldVideoPoster} playsInline aria-hidden="true" aria-label="Kickstage animation video">
                <source src={foldVideoWebm} type="video/webm" />
                <source src={foldVideoMp4} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
