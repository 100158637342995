import React , { Fragment} from 'react';
import Layout from '../components/layout/layout';
import { graphql, useStaticQuery } from "gatsby";
import { Hero } from '../components/Hero/Hero';
import { TextPhotoSection } from '../components/TextPhotoSection/TextPhotoSection';
import WorkSlider from '../components/WorkSlider/WorkSlider';
import Feedback from '../components/Feedback/Feedback';
import FadeDown from '../components/FadeDown/FadeDown';
import { WhatWeDoSection } from '../components/WhatWeDoSection/WhatWeDoSection';
import CtaSection from '../components/CtaSection/CtaSection';
import { Script } from "gatsby"

const IMAGE_QUERY = graphql`
{
  aboutUsImage: file(relativePath: {eq: "homepage/about-us.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        width: 800
        placeholder: BLURRED
        formats: [AUTO, WEBP, AVIF]
      )
    }
  }
  consulting1: file(relativePath: {eq: "homepage/what-we-do1.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        width: 700
        placeholder: BLURRED
        formats: [AUTO, WEBP, AVIF]
      )
    }
  }
}
`

const Homepage = () => {

  const tempImage = useStaticQuery(IMAGE_QUERY);


  return (
    <Layout
      pageTitle="Real-time Software For Business-Critical Applications"
      description="With the precision of rocket scientists and the passion of space enthusiasts we shape digital change around the globe."
    >

      <Hero
        homepage
        textLine1={<Fragment>We build <span className='featured'>real-time</span> </Fragment>}
        textLine2="software for"
        textLine3="business-critical"
        textLine4="applications"
        link="#what-we-do"
        typingText={['web app', 'mobile app', 'custom software', 'IoT product', 'reality']}
      />

      <FadeDown>
        <WhatWeDoSection
          title="What we do?"
          description="Optimizing your business for the digital world is quite a process – and every industry is at a different stage in this journey."
          btnLink="/our-work/"
        />
      </FadeDown>
      <FadeDown>
        <WorkSlider
          title="Our work"
          description="We enjoy nothing more than empowering brands to reach their digital potential. With Kickstage, the digital world is at your feet."
          variant="homepage"
        />
      </FadeDown>
      <FadeDown>
        <TextPhotoSection
          title="Working @ Kickstage"
          description="As the first software company in Croatia, Kickstage has introduced the four-day work week. We use Fridays for side-projects, to learn and experiment in our lab or for a hike on Sljeme to recharge the batteries. From Monday to Thursday, we work either from our office in Zagreb or remotely. We are an incredibly motivated team and eager to drive true digital innovation."
          image={tempImage.aboutUsImage}
          btnLink="/careers/"
          btnText="Discover open positions"
          radialTextDecoration
          radialTextDecorationColor="yellow"
          radialTextDecorationPosition="bottom_left"
        />
      </FadeDown>
      <FadeDown>
        <Feedback />
      </FadeDown>
      <CtaSection
        title="Let's Chat"
        label="What Can We Do For You?"
        link="/contact/"
      />
     <Script src="/custom.js" />
    </Layout>
  );
};

export default Homepage;

