import React from 'react';
import './card-work.scss';
import { Button } from '../Button/Button';
import { Heading } from '../Heading/Heading';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { navigate } from "gatsby"

export const CardWorkSlider = ({ link, projectTitle, projectDescription, projectCtaText, image, variation }) => {
    const parsedImage = getImage(image);
    return (
        <div
            className={`card-work card-work--${variation}`}
            onClick={() => navigate(link)}
            role="presentation"
        >
            <div className="card-work__holder">
                <div className="details">
                    <div className="info">
                        <Heading
                            level="h3"
                            slider
                        >
                            {projectTitle}
                        </Heading>
                        <p className="description">{projectDescription}</p>
                        <div className="cta">
                            <Button
                                link={link}
                                special
                            >
                                {projectCtaText}
                            </Button>
                        </div>
                    </div>
                    <div className="illustration">
                        <GatsbyImage
                            image={parsedImage}
                            className="illustration-img rspimg"
                            alt={projectTitle}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
