import React, { useState } from "react";
import "./service-item-card.scss";
import { Heading } from "../Heading/Heading";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export const ExpandableServiceCard = ({ title, description, icon }) => {
  const parsedImage = getImage(icon);
  const [isActive, setActive] = useState("false");

  const handleToggle = () => {
    setActive(!isActive);
  };
  return (
    <div
      onClick={handleToggle}
      aria-hidden="true"
      className={isActive ? "service" : "service service--open"}
    >
      <div className="service__holder">
        <div className="title-holder">
          {title && (
            <Heading level="h3" service>
              {title}
            </Heading>
          )}
          {icon && (
            <GatsbyImage
              image={parsedImage}
              alt={description}
              className="service-icon"
            />
          )}
        </div>
        <p className="description">{description}</p>
      </div>
    </div>
  );
};
